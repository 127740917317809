<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Vehicle Category</label>
            <b-select
              v-model="filter.vehicle_category"
              :options="filterVehicleCategory"
              @change="onFilterChange"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onShowNewForm"
          >
            New Vehicle
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>
        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="onShowEditForm(row.item)"
          >
            Edit
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-vehicle-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="vehicle type"
            vid="vehicle_category"
            rules="required"
          >
            <div class="form-group">
              <label for="vehicle_category">Vehicle Category</label>
              <b-form-select
                id="vehicle_category"
                v-model="vehicle.vehicle_category"
                :options="list.vehicleCategories"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Vehicle Category --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
            rules="required|max:100"
          >
            <div class="form-group">
              <label for="name">Name</label>
              <b-input
                id="name"
                v-model="vehicle.name"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="license plate"
            vid="license_plate"
            rules="required|max:15"
          >
            <div class="form-group">
              <label for="license_plate">License Plate Number</label>
              <b-input
                id="license_plate"
                v-model="vehicle.license_plate"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter license plate"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="vehicle_type"
            vid="vehicle_type"
            rules="required"
          >
            <b-form-group>
              <label for="vehicle_type">Vehicle Type</label>
              <b-form-select
                id="vehicle_type"
                v-model="vehicle.vehicle_type"
                :options="vehicleTypes"
                type="text"
                :state="errors.length > 0 ? false : null"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Vehicle Type --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="vehicle.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, LogisticsOfficerVehicleService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import helper from '@/mixins/helper'

export default {
  name: 'LogisticsOfficerVehicles',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Vehicles'
    }
  },

  mixins: [formatter, helper],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        drivers: [],
        vehicleCategories: []
      },
      vehicle: {
        id: 0,
        name: '',
        license_plate: '',
        registration_expiry: '',
        vehicle_category: null,
        vehicle_type: 'rented',
        active: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'name', sortable: true },
          { key: 'vehicle_category_name', sortable: true },
          { key: 'license_plate' },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      filter: {
        vehicle_category: 'All'
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Vehicle' : 'New Vehicle'
    },
    filterVehicleCategory () {
      return [{ value: 'All', text: 'All' }].concat(this.list.vehicleCategories)
    }
  },

  mounted () {
    core.index()
    this.fetchVehicleCategoryList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await LogisticsOfficerVehicleService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_vehicle_category=${this.filter.vehicle_category}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async fetchVehicleCategoryList () {
      await SharedListService.getVehicleCategories().then(({ data }) => {
        this.list.vehicleCategories = data.map(({ id, name }) => ({
          text: name,
          value: id
        }))
      })
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    onShowNewForm () {
      this.state.editing = false
      this.vehicle.id = 0
      this.vehicle.name = ''
      this.vehicle.license_plate = ''
      this.vehicle.registration_expiry = ''
      this.vehicle.vehicle_category = null
      this.vehicle.vehicle_type = 'rented'
      this.vehicle.active = 1
      this.$bvModal.show('modal-vehicle-form')
    },

    onShowEditForm (item) {
      this.state.editing = true
      this.vehicle.id = item.id
      this.vehicle.name = item.name
      this.vehicle.license_plate = item.license_plate
      this.vehicle.vehicle_category = item.vehicle_category_id
      this.vehicle.vehicle_type = item.vehicle_type
      this.vehicle.active = item.active
      this.$bvModal.show('modal-vehicle-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create vehicle?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        LogisticsOfficerVehicleService.post(this.vehicle).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-vehicle-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        LogisticsOfficerVehicleService.put(this.vehicle).then(({ data }) => {
          this.$bvModal.hide('modal-vehicle-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.vehicle.id })
            row.name = data.vehicle.name
            row.license_plate = data.vehicle.license_plate
            row.registration_expiry = data.vehicle.registration_expiry
            row.vehicle_category_name = data.vehicle.vehicle_category.name
            row.vehicle_category_id = data.vehicle.vehicle_category_id
            row.vehicle_type = data.vehicle.vehicle_type
            row.active = data.vehicle.active
            row.updated_at = data.vehicle.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
